<mat-grid-list cols="1" rowHeight="100%">
    <mat-grid-tile colspan="1">
        <div class="user_card">
            <div class="brand_logo_container">
                <img [src]="logoUrl" alt="Logo" class="brand_logo" />
            </div>
            <h3><b>E-mailadres bevestigd bij {{brandName}}</b></h3>
            <p>Uw e-mailadres is bevestigd.</p>
            <p>Uw account wordt momenteel beoordeeld door een van de medewerkers.</p>
            <p>Bij goedkeuring van uw account ontvangt u een bericht.</p>
        </div>
    </mat-grid-tile>
</mat-grid-list>