import { Injectable, InjectionToken } from "@angular/core";
import { LocatieserverApiClient } from "../location-api.service";
import { lastValueFrom } from "rxjs";
import { PDocLocation } from "./i-pdoc-location";

@Injectable({
    providedIn: "root"
})
export class LocationService {

    public constructor(
        private readonly locationApiClient: LocatieserverApiClient
    ) { }

    public async getCoordinatesAsync(postalCode: string, houseNumber: string): Promise<PDocLocation> {
        // eslint-disable-next-line no-undefined
        const promise = this.locationApiClient.free(undefined, undefined, undefined, undefined, [ `postcode:${postalCode}`, `huis_nlt:${houseNumber}` ]);
        const response = await lastValueFrom(promise);

        if (response.response && response.response.docs) {
            if (response.response.docs.length < 1) {
                // no result
                throw new Error("No coordinates found");
            } else {
                // Assume when more than 0 results, the first one is the correct (and only) one as in the netherlands the combination of postal code and house number is unique
                // Assume the following format 'POINT(0.000 0.000)'
                const [ doc ] = response.response.docs;
                const centroide_ll = doc.centroide_ll as string;
                const cleanedCentroide = centroide_ll.replace("POINT(", "").replace(")", "");
                const split = cleanedCentroide.split(" ");

                const result: PDocLocation = {
                    houseNumber: doc.huisnummer,
                    postalCode: doc.postcode,
                    street: doc.straatnaam,
                    city: doc.woonplaatsnaam,
                    longitude: parseFloat(split[0]),
                    latitude: parseFloat(split[1]),
                };

                return result;
            }
        }

        throw new Error("No coordinates found");
    }
}

export const LOCATION_SERVICE: InjectionToken<LocationService> = new InjectionToken("LOCATION_SERVICE");
