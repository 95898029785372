<div class="screen-center">
	<div class="user_card">
		<div class="brand_logo_container">
			<img [src]="brandImage" alt="Logo" class="brand_logo" />
		</div>
		<h3>
			<b>Nieuw wachtoord aanvragen bij {{ brandName }} </b>
			<mat-error *ngIf="form.errors" color="warn">
				{{ form.errors }}
			</mat-error>
		</h3>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<p>
				Vul uw e-mailadres in die overeenkomt met uw account. <br />
				U ontvangt een e-mail met instructies om een nieuw wachtwoord in te vullen.
			</p>
			<mat-form-field>
				<mat-label>E-mail</mat-label>
				<input matInput type="text" [formControl]="this.form.controls.email" placeholder="Email" />
				<mat-error *ngIf="this.form.controls.email.touched && this.form.controls.email.invalid" color="warn">
					E-mail is verplicht!
				</mat-error>
			</mat-form-field>
			<button mat-raised-button color="primary" type="submit">Wachtwoord opnieuw aanvragen</button>
            <button *ngIf="showBackToLogin" mat-button class="back-button" routerLink="/login" color="primary" >Terugkeren naar inloggen</button>
		</form>
	</div>
</div>
