import { Component, Inject, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { AuthService } from "./services/auth/auth.service";
import { TenantPublicResourceResult } from "./services/identity.service";
import { StyleService } from "./services/style/style.service";
import { THEMING_SERVICE, ThemingService } from "./services/theming.service";
import { RoutingUtils } from "./services/RoutingUtils";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: [ "./app.component.scss" ]
})
export class AppComponent implements OnDestroy {
    protected isLoading: boolean;

    private readonly componentDestroyed: Subject<void> = new Subject();

    public constructor(
        @Inject(THEMING_SERVICE) private readonly themingService: ThemingService,
        private readonly authService: AuthService,
        private readonly styleService: StyleService,
        route: ActivatedRoute,
    ) {
        this.isLoading = true;

        // Check if params includes tenantName
        if (!window.location.search.toLowerCase().includes("tenantname")) {
            // Check if tenantName is in sessionStore
            const tenantNameSessionStore = window.sessionStorage.getItem("tenantName");

            if (tenantNameSessionStore != null) {
                this.setTenantName(tenantNameSessionStore);
            } else {
                throw new Error("tenantName not provided");
            }
        }

        route.queryParams.pipe(takeUntil(this.componentDestroyed)).subscribe((queryParams: Params) => {
            const tenantName: string | null = RoutingUtils.getQueryParameterInsensitive(queryParams, "tenantname");

            if (tenantName != null) {
                window.sessionStorage.setItem("tenantName", tenantName);
                this.setTenantName(tenantName);
            }
        });
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    private setTenantName(tenantName: string): void {
        this.authService.setTenantName(tenantName);

        this.styleService.getPublicStyling(tenantName).then((tenantPublicResourceResult: TenantPublicResourceResult) => {
            if (tenantPublicResourceResult.webStyles) {
                this.themingService.updateStyling(tenantPublicResourceResult.webStyles);
            }

            document.body.style.setProperty("background-color", "var(--primary-color)");

            this.isLoading = false;
        }, (reason) => {
            throw new Error(`Could not retrieve styling.\n${reason}`);
        });
    }
}
