import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { AppConfig } from "../config/app-config";
import { AccountsIdentityClient, LoginResource } from "../identity.service";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private readonly identityClient: AccountsIdentityClient;
    private tenantName: string;

    public constructor(http: HttpClient, appConfig: AppConfig) {
        this.identityClient = new AccountsIdentityClient(http, appConfig.Identity.BaseUrl);
    }

    public async login(username: string, password: string, redirectUrl: string): Promise<void> {
        const loginResource: LoginResource = {
            username,
            password
        };

        await lastValueFrom(this.identityClient.loginPOST(loginResource));
        window.location.href = redirectUrl;
    }

    public setTenantName(name: string) {
        this.tenantName = name;
    }

    public getTenantName(): string {
        return this.tenantName;
    }
}
