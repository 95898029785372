import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AccountComponent } from "./modules/account/account/account.component";
import { LoginComponent } from "./modules/auth/components/login/login.component";
import { PasswordResetCompleteComponent } from "./modules/auth/components/password/password-reset-complete/password-reset-complete.component";
import { PasswordResetRequestCompleteComponent } from "./modules/auth/components/password/password-reset-request-complete/password-reset-request-complete.component";
import { PasswordResetRequestComponent } from "./modules/auth/components/password/password-reset-request/password-reset-request.component";
import { PasswordResetComponent } from "./modules/auth/components/password/password-reset/password-reset.component";
import { RegisterCompleteComponent } from "./modules/auth/components/register/register-complete/register-complete.component";
import { RegisterCompletedComponent } from "./modules/auth/components/register/register-completed/register-completed.component";
import { RegisterErrorComponent } from "./modules/auth/components/register/register-error/register-error.component";
import { RegisterWaitingForApprovalComponent } from "./modules/auth/components/register/register-waiting-for-approval/register-waiting-for-approval.component";
import { TosComponent } from "./modules/public/tos/tos.component";
import { RoutingUtils } from "./services/RoutingUtils";
import { RegisterBasicComponent } from "./modules/auth/components/register/register-basic/register-basic.component";

@NgModule({
    imports: [
        RouterModule.forRoot(RoutingUtils.prepare([
            { path: "", redirectTo: "Login", pathMatch: "full" },
            { path: "Login", component: LoginComponent },
            { path: "TermsOfService", component: TosComponent },
            { path: "PasswordReset", component: PasswordResetComponent },
            { path: "PasswordReset/Complete", component: PasswordResetCompleteComponent },
            { path: "PasswordReset/Request", component: PasswordResetRequestComponent },
            { path: "PasswordReset/Request/Complete", component: PasswordResetRequestCompleteComponent },
            { path: "Register", component: RegisterBasicComponent },
            { path: "Register/Complete", component: RegisterCompleteComponent },
            { path: "Register/WaitingForApproval", component: RegisterWaitingForApprovalComponent },
            { path: "Register/Errors", component: RegisterErrorComponent },
            { path: "Register/Completed", component: RegisterCompletedComponent },
            { path: "Account", component: AccountComponent }, // When changed also change the account.component.ts
        ]))
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
