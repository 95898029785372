import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DialogData } from "src/app/modules/shared/generic-dialog/dialog-data";
import { GenericDialogComponent } from "src/app/modules/shared/generic-dialog/generic-dialog.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { AccountsIdentityClient } from "src/app/services/identity.service";
import { StyleService } from "src/app/services/style/style.service";
import { ValidatorExpressions } from "src/app/services/validatorExpressions";
import { PasswordResetRequestForm } from "./password-reset-request-form";

@Component({
    selector: "app-password-reset-request",
    templateUrl: "./password-reset-request.component.html",
    styleUrls: [ "./password-reset-request.component.scss" ]
})
export class PasswordResetRequestComponent {
    protected readonly form: FormGroup<PasswordResetRequestForm>;
    protected readonly brandImage: string;
    protected readonly brandName: string;
    protected readonly showBackToLogin: boolean;

    public constructor(
        private readonly auth: AuthService,
        private readonly accountsIdentityClient: AccountsIdentityClient,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        styleService: StyleService,
    ) {
        this.form = new FormGroup<PasswordResetRequestForm>({
            email: new FormControl("", { validators: [ Validators.required, Validators.pattern(ValidatorExpressions.emailExpression) ], nonNullable: true })
        });

        this.brandImage = styleService.getLogoUrl();
        this.brandName = this.auth.getTenantName();

        this.showBackToLogin = window.sessionStorage.getItem("redirectUrl") != null;
    }

    protected async submit(): Promise<void> {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const email = this.form.controls.email.value;
            await this.accountsIdentityClient.request(email).toPromise();
            await this.router.navigate([ "passwordreset/request/complete" ]);
        }
    }

    protected openErrorDialog(title: string, error: string[]): void {
        const dialogData: DialogData = {
            title,
            message: error,
            icon: "report",
            iconColor: "#ed4337",
        };
        this.dialog.open(GenericDialogComponent, { data: dialogData, disableClose: false });
    }
}
