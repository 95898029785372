<div class="screen-center">
	<div class="user_card">
		<div class="brand_logo_container">
			<img [src]="brandImage" alt="Logo" class="brand_logo" />
		</div>
		<h3>
			<b>Wachtwoord gewijzigd bij {{ brandName }} </b>
		</h3>
        <p>Uw wachtwoord om in te loggen bij {{ brandName }} is gewijzigd. U kunt nu inloggen vanaf de inlogpagina.</p>
    </div>
</div>