import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params } from "@angular/router";
import { Subject } from "rxjs";
import { DialogData } from "src/app/modules/shared/generic-dialog/dialog-data";
import { GenericDialogComponent } from "src/app/modules/shared/generic-dialog/generic-dialog.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { AppConfig } from "src/app/services/config/app-config";
import { TenantResourceResult } from "src/app/services/identity.service";
import { StyleService } from "src/app/services/style/style.service";
import { LoginForm } from "./login-form.model";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: [ "./login.component.scss" ]
})
export class LoginComponent implements OnInit, OnDestroy {
    protected readonly form: FormGroup<LoginForm>;
    protected readonly logoUrl: string;
    protected readonly tenantName: string;
    protected readonly disableRegistration: boolean;
    protected readonly disableSocialLogin: boolean;

    protected microsoftLoginUrl: string = "";
    protected googleLoginUrl: string = "";

    protected IsLoading: boolean;
    protected tenant: TenantResourceResult;
    protected errorMessage: string | null;

    private redirectUrl: string | null;
    private readonly componentDestroyed: Subject<void> = new Subject();

    public constructor(
        private readonly route: ActivatedRoute,
        private readonly auth: AuthService,
        private readonly dialog: MatDialog,
        private readonly appConfig: AppConfig,
        styleService: StyleService
    ) {
        this.IsLoading = false;

        this.disableRegistration = this.appConfig.DisableRegistrationNavigation;
        this.disableSocialLogin = this.appConfig.DisableSocialLogin;

        this.form = new FormGroup<LoginForm>({
            username: new FormControl("", { validators: Validators.required, nonNullable: true }),
            password: new FormControl("", { validators: Validators.required, nonNullable: true }),
        });

        this.tenantName = auth.getTenantName();
        this.logoUrl = styleService.getLogoUrl();
    }

    public ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams: Params) => {
            if (queryParams.redirectUrl != null) {
                this.redirectUrl = queryParams.redirectUrl;
            }
            if (queryParams.errorMessage != null) {
                this.errorMessage = queryParams.errorMessage;
            }
        });

        if (this.redirectUrl == null || this.redirectUrl === "") {
            // Check if redirectUrl is in sessionStore
            this.redirectUrl = window.sessionStorage.getItem("redirectUrl");

            if (this.redirectUrl == null || this.redirectUrl === "") {

                this.openErrorDialog("Geen redirect url aanwezig", [
                    "Er is iets mis gegaan bij het laden van deze pagina.",
                    "Benader de website via de hoofdpagina.",
                    "Als deze melding opnieuw optreed, neem aub contact op met het bedrijf."
                ]);
                console.error("No redirectUrl");
            }
        } else {
            window.sessionStorage.setItem("redirectUrl", this.redirectUrl);
        }

        this.microsoftLoginUrl = `${this.appConfig.Identity.BaseUrl}/api/v1/Accounts/login/Microsoft?redirectUrl=${encodeURIComponent(this.redirectUrl ?? "")}`;
        this.googleLoginUrl = `${this.appConfig.Identity.BaseUrl}/api/v1/Accounts/login/Google?redirectUrl=${encodeURIComponent(this.redirectUrl ?? "")}`;
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    protected openErrorDialog(title: string, error: readonly string[]): void {
        const dialogData: DialogData = {
            title,
            message: error as string[],
            icon: "report",
            iconColor: "#ed4337",
        };
        this.dialog.open(GenericDialogComponent, { data: dialogData, disableClose: true });
    }

    protected async login(): Promise<void> {
        this.IsLoading = true;
        this.errorMessage = "";

        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();

        if (this.form.invalid) {
            this.IsLoading = false;
            return;
        }

        try {
            await this.auth.login(this.form.controls.username.value, this.form.controls.password.value, this.redirectUrl ?? "");
        } catch (error: unknown) {
            this.form.controls.password.reset();

            switch (error) {
                case "The username and/or password is invalid!":
                    this.errorMessage = "De gebruikersnaam en/of het wachtwoord is onjuist.";
                    break;

                case "Please confirm your email, before logging in!":
                    this.errorMessage = "Het e-mailadres van dit account moet worden bevestigd voor u kunt inloggen.";
                    break;

                case "The username is disabled, please contact an Administrator":
                    this.errorMessage = `Uw account is uitgeschakeld, neem contact op met ${this.tenantName}.`;
                    break;

                case "The username is not approved yet, please contact the tenant":
                    this.errorMessage = `Uw account is nog niet goedgekeurd, neem contact op met ${this.tenantName}.`;
                    break;

                default:
                    throw new Error(`An unexpected error value was received, retrieved ${error}`);
            }

            this.IsLoading = false;
        }
    }
}
