import { HttpStatusCode } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import { DialogData } from "src/app/modules/shared/generic-dialog/dialog-data";
import { GenericDialogComponent } from "src/app/modules/shared/generic-dialog/generic-dialog.component";
import { ApiValidationException } from "src/app/services/api-validation-exception";
import { ApiException } from "src/app/services/identity.service";
import { PromiseError } from "./promise-error";


@Injectable({ providedIn: "root" })
export class GenericErrorHandler implements ErrorHandler {

    private dialogRef: MatDialogRef<GenericDialogComponent> | null;
    private readonly genericErrorText: string[] = [ "Er is iets misgegaan bij het uitvoeren van uw laatste actie." ];

    public constructor(
        private readonly dialog: MatDialog,
        private readonly zone: NgZone
    ) { }

    public handleError(error: any): void {
        if (this.dialogRef?.getState() !== MatDialogState.OPEN) {
            // only open one error dialog at a time
            this.handleGlobalErrors(error);
        }
    }

    private handleGlobalErrors(error: any) {
        const errorMessages: string[] = [];
        let shouldShowDialog: boolean = false;

        if (error instanceof ApiException) {
            shouldShowDialog = true;
            if (error.status >= (HttpStatusCode.BadRequest as number) && error.status < (HttpStatusCode.InternalServerError as number)) {
                // client side request error handling (fluent assertions from backend) if available
                errorMessages.push(...this.genericErrorText);
            }
        } else {
            const promiseError = error as PromiseError<ApiValidationException>;
            const apiValidationException: ApiValidationException | null = promiseError.rejection ?? error as ApiValidationException | null;

            if (apiValidationException != null) {
                shouldShowDialog = true;

                if (apiValidationException.errors == null) {
                    errorMessages.push(...this.genericErrorText);

                } else {
                    for (const key of Object.keys(apiValidationException.errors)) {
                        errorMessages.push(...apiValidationException.errors[key]);
                    }
                }
            }
        }

        if (shouldShowDialog) {
            this.openErrorDialog(errorMessages);
        }
    }

    private openErrorDialog(errorMessages: string[]) {
        this.zone.run(() => {
            const data: DialogData = {
                title: "Error",
                message: errorMessages,
                icon: "error",
                iconColor: "var(--warning-color)",
                primaryButtonText: "Sluiten",
                primaryButtonColor: "primary",
                primaryButtonAction: () => { /* Ignored */ }
            };

            this.dialogRef = this.dialog.open(GenericDialogComponent, { data });
        });
    }
}
