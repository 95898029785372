import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "./dialog-data";

@Component({
    selector: "app-generic-dialog",
    templateUrl: "./generic-dialog.component.html",
    styleUrls: [ "./generic-dialog.component.scss" ]
})
export class GenericDialogComponent {
    protected numberOfButtons: number;

    public constructor(
        protected dialogRef: MatDialogRef<GenericDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: DialogData
    ) {
        this.numberOfButtons = 0;
        if (data.primaryButtonAction != null) {
            this.numberOfButtons++;

            if (data.secondaryButtonAction != null) {
                this.numberOfButtons++;
            }
        }
    }

    protected trackBy(_index: number, item: string) {
        return item;
    }
}
