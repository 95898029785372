import { Inject, Injectable } from "@angular/core";
import { IDENTITY_API_BASE_URL, TenantPublicResourceResult, TenantsIdentityClient } from "../identity.service";

@Injectable({
    providedIn: "root"
})
export class StyleService {
    private readonly tenantName: string;
    private tenantPublicResourceResult: TenantPublicResourceResult;

    public constructor(
        private readonly tenantIdentityClient: TenantsIdentityClient,
        @Inject(IDENTITY_API_BASE_URL) private readonly baseUrl: string
    ) {}

    public async getPublicStyling(tenantName: string | null = null): Promise<TenantPublicResourceResult> {
        if (tenantName !== null && this.tenantName !== tenantName) {
            const resourceResult: TenantPublicResourceResult | null = await this.tenantIdentityClient.tenantsGET(tenantName).toPromise() ?? null;

            if (resourceResult == null) {
                throw new Error("Could not retrieve public tenant data");
            }

            this.tenantPublicResourceResult = resourceResult;
        }

        return this.tenantPublicResourceResult;
    }

    public getLogoUrl(): string {
        const result = this.tenantPublicResourceResult;
        return `${this.baseUrl}/api/v1/Tenants/${result.id}/logo`;
    }

    public getPublicTenant(): TenantPublicResourceResult {
        return this.tenantPublicResourceResult;
    }

    public getTermsOfService(): string {
        return this.tenantPublicResourceResult.termsOfService;
    }
}
