<mat-grid-list cols=1 rowHeight="100%">
    <mat-grid-tile colspan=1>
        <div class="user_card">
            <div class="brand_logo_container">
                <img [src]="brandImage" alt="Logo" class="brand_logo" />
            </div>
            <h3>
                <b>Registeren bij {{ brandName }} </b>
                <mat-error *ngIf="form.errors" color="warn">
                    {{ form.errors.invalidRegister }}
                </mat-error>
            </h3>
            <form [formGroup]="form">
                <mat-vertical-stepper #registrationStepper linear>
                    <mat-step label="Persoonsgegevens" [formGroup]="userInformationForm" [stepControl]="userInformationForm" errorMessage="Persoonsgegevens zijn vereist">
                        <mat-grid-list cols="2" rowHeight="75px">
                            <mat-grid-tile colspan="1">
                                <mat-form-field>
                                    <mat-label>Voornaam</mat-label>
                                    <input matInput class="form-control" [formControl]="this.userInformationForm.controls.firstName" type="text" required />
                                    <mat-error *ngIf="this.userInformationForm.controls.firstName.touched && this.userInformationForm.controls.firstName.invalid" color="warn">
                                        Voornaam is verplicht.
                                    </mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <mat-form-field>
                                    <mat-label>Achternaam</mat-label>
                                    <input matInput class="form-control" [formControl]="this.userInformationForm.controls.lastName" type="text" required />
                                    <mat-error *ngIf="this.userInformationForm.controls.lastName.touched && this.userInformationForm.controls.lastName.invalid" color="warn">
                                        Achternaam is verplicht.
                                    </mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="2">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Telefoonnummer</mat-label>
                                    <input matInput class="form-control" [formControl]="this.userInformationForm.controls.phoneNumber" placeholder="06-12345678" type="text" />
                                    <mat-error *ngIf="this.userInformationForm.controls.phoneNumber.touched && this.userInformationForm.controls.phoneNumber.invalid" color="warn">
                                        Dit telefoonnummer is ongeldig.
                                    </mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <div>
                            <button mat-raised-button color="primary" matStepperNext (click)="validateUserInformationAndNextStep()" type="button">Volgende</button>
                        </div>
                    </mat-step>
                    <mat-step label="Login informatie" [formGroup]="loginInformationForm" [stepControl]="loginInformationForm" errorMessage="Logingegevens zijn vereist">
                        <mat-grid-list cols="1" rowHeight="70px">
                            <mat-grid-tile colspan="1">
                                <mat-form-field class="full-width-field">
                                    <mat-label>E-mailadres</mat-label>
                                    <input matInput class="form-control" [formControl]="this.loginInformationForm.controls.email" placeholder="voorbeeld@outlook.com" type="email" required />
                                    <mat-error *ngIf="this.loginInformationForm.controls.email.touched && this.loginInformationForm.controls.email.invalid" color="warn">
                                        Een correct e-mailadres is verplicht.
                                    </mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile>
                                <mat-form-field class="full-width-field">
                                    <mat-label>Wachtwoord</mat-label>
                                    <input matInput class="form-control password-input" [formControl]="this.loginInformationForm.controls.password" placeholder="************" type="password" required />
                                    <mat-icon class="helper-tooltip" matTooltip="Een wachtwoord moet minimaal 12 tekens lang zijn" aria-label="Some icon">info</mat-icon>
                                    <mat-error *ngIf="this.loginInformationForm.controls.password.touched && this.loginInformationForm.controls.password.invalid" color="warn">
                                        Een wachtwoord wat voldoet aan de eisen is verplicht.
                                    </mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                            <mat-grid-tile>
                                <mat-form-field class="full-width-field">
                                    <mat-label>Herhaal wachtwoord</mat-label>
                                    <input matInput class="form-control password-input" [formControl]="this.loginInformationForm.controls.passwordRepeat" placeholder="************" type="password" required />
                                    <mat-icon class="helper-tooltip" matTooltip="Een wachtwoord moet minimaal 12 tekens lang zijn" aria-label="Some icon">info</mat-icon>
                                    <mat-error *ngIf="this.loginInformationForm.controls.passwordRepeat.touched && this.loginInformationForm.controls.passwordRepeat.invalid" color="warn">
                                        De wachtwoorden moeten overeenkomen.
                                    </mat-error>
                                </mat-form-field>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-grid-list cols="1" rowHeight="90px">
                            <mat-grid-tile colspan="1">
                                <div>
                                    <div class="toggle-container">
                                        <mat-label>Mogen wij uw e-mailadres gebruiken om u te informeren?</mat-label>
                                        <mat-slide-toggle class="form-control" color="primary" [formControl]="this.loginInformationForm.controls.emailUsage"></mat-slide-toggle>
                                    </div>
                                    <mat-error *ngIf="this.loginInformationForm.controls.emailUsage.touched && this.loginInformationForm.controls.emailUsage.invalid">
                                        Dit is verplicht om uw account te kunnen activeren.
                                    </mat-error>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <div>
                                    <div class="toggle-container">
                                        <mat-label>Gaat u akkoord met onze <a id="terms-of-service-link" class="clickable" target="_blank" [href]="tosUrl">Algemene voorwaarden</a> en <a id="privacy-statement-link" class="clickable" target="_blank" [href]="privacyUrl">privacy verklaring</a>?</mat-label>
                                        <mat-slide-toggle class="form-control" color="primary" [formControl]="this.loginInformationForm.controls.eulaAcceptance"></mat-slide-toggle>
                                    </div>
                                    <mat-error *ngIf="this.loginInformationForm.controls.eulaAcceptance.touched && this.loginInformationForm.controls.eulaAcceptance.invalid">
                                        Dit is verplicht om uw account te kunnen activeren.
                                    </mat-error>
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <div>
                                    <div class="toggle-container">
                                        <mat-label>Mogen wij uw gegevens gebruiken om het product te verbeteren?</mat-label>
                                        <mat-slide-toggle class="form-control" color="primary" [formControl]="this.loginInformationForm.controls.isHelpingImprovingTheProduct"></mat-slide-toggle>
                                    </div>
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <div class="control-buttons">
                            <button mat-button matStepperPrevious type="button">Vorige</button>
                            <button #registerButton mat-raised-button color="primary" class="btn button" type="submit" (click)="register(registerButton)">Registeren</button>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
                <div *ngIf="showBackToLogin" class="return-to-login">
                    <a routerLink="/login">Ga terug naar login</a>
                </div>
            </form>
        </div>
    </mat-grid-tile>
</mat-grid-list>
