<mat-grid-list cols="1" rowHeight="100%">
    <mat-grid-tile rowspan="1">
        <div class="user_card">
            <div class="brand_logo_container">
                <img [src]="logoUrl" alt="Logo" class="brand_logo" />
            </div>
            <div class="below-logo-container">
                <h3>
                    <b>Inloggen bij {{tenantName}}</b>
                    <mat-error *ngIf="errorMessage" color="warn">{{errorMessage}}</mat-error>
                </h3>
                <ng-container *ngIf="!IsLoading; else loadingTemplate">
                    <form (ngSubmit)="login()" [formGroup]="form">
                        <mat-form-field>
                            <mat-icon matPrefix>person</mat-icon>
                            <mat-label>Gebruikersnaam</mat-label>
                            <input matInput type="text" [formControl]="form.controls.username" placeholder="Gebruikersnaam" />
                            <mat-error *ngIf="form.controls.username.touched && form.controls.username.invalid" color="warn">
                                Gebruikernaam is verplicht!
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-icon matPrefix>vpn_key</mat-icon>
                            <mat-label>Wachtwoord</mat-label>
                            <input class="password-input" matInput type="password" [formControl]="form.controls.password" placeholder="Wachtwoord" />
                            <mat-error *ngIf="form.controls.password.touched && form.controls.password.invalid" color="warn">
                                Wachtwoord is verplicht!
                            </mat-error>
                        </mat-form-field>
                        <button mat-raised-button class="submit-button" color="primary" type="submit">Login</button>
                        <div class="extraOptions">
                            <div>
                                <button mat-button routerLink="/passwordreset/request" type="button" color="primary">
                                    Wachtwoord vergeten?
                                </button>
                            </div>
                            <div>
                                <button *ngIf="!this.disableRegistration" mat-button routerLink="/register" type="button" color="primary">
                                    Registeren
                                </button>
                            </div>
                        </div>
                        <div class="external-login-buttons" *ngIf="!this.disableSocialLogin">
                            <div class="or">
                                <span>OR</span>
                            </div>
                            <div>
                                <a class="microsoft-login" [href]="microsoftLoginUrl">
                                    <img src="/assets/icons/ms-symbollockup_mssymbol_19.svg" alt="microsoft-login" />
                                    Aanmelden met Microsoft
                                </a>
                            </div>
                            <div>
                                <a class="gsi-material-button" [href]="googleLoginUrl">
                                    <div class="gsi-material-button-state"></div>
                                    <div class="gsi-material-button-content-wrapper">
                                        <div class="gsi-material-button-icon">
                                            <img src="/assets/icons/gsi-logo.svg" alt="google-login">
                                        </div>
                                        <span class="gsi-material-button-contents">Aanmelden met Google</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </mat-grid-tile>
</mat-grid-list>

<ng-template #tempTenantName>
    <b>Inloggen bij...</b>
</ng-template>

<ng-template #loadingTemplate>
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
