import { Component } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { StyleService } from "src/app/services/style/style.service";

@Component({
    selector: "app-register-waiting-for-approval",
    templateUrl: "./register-waiting-for-approval.component.html",
    styleUrls: [ "./register-waiting-for-approval.component.scss" ]
})
export class RegisterWaitingForApprovalComponent {
    protected readonly logoUrl: string;
    protected readonly brandName: string;

    public constructor(authService: AuthService, styleService: StyleService) {
        this.brandName = authService.getTenantName();
        this.logoUrl = styleService.getLogoUrl();
    }
}
