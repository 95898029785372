<div class="screen-center">
	<div class="user_card">
		<div class="brand_logo_container">
			<img [src]="brandImage" alt="Logo" class="brand_logo" />
		</div>
		<h3>
			<b>Wachtwoord aanvraag ingediend bij {{ brandName }} </b>
		</h3>
        <p>
            De aanvraag is ingediend bij {{ brandName }}.<br />
            U ontvangt binnenkort een e-mail met een link om uw wachtwoord te wijzigen.
        </p>
    </div>
</div>