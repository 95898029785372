import { Component } from "@angular/core";
import { StyleService } from "src/app/services/style/style.service";
import { AppConfig } from "../../../services/config/app-config";

@Component({
    selector: "app-tos",
    templateUrl: "./tos.component.html",
    styleUrls: [ "./tos.component.scss" ]
})
export class TosComponent {
    protected tenantTermsOfService: string;
    protected tenantName: string;
    protected tosPdfUrl: string;

    public constructor(
        private readonly styleService: StyleService,
        config: AppConfig,
    ) {
        this.tosPdfUrl = config.TermsOfServicePdfUrl;
        const tenant = this.styleService.getPublicTenant();
        this.tenantTermsOfService = tenant.termsOfService;
        this.tenantName = tenant.name;
    }
}
