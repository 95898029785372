<div class="screen-center">

    <mat-card>
        <mat-card-content>
            <h1>Login providers</h1>

            <div class="external-login-buttons">
                <div class="external-account-container">
                    <span class="microsoft-login">
                        <img src="/assets/icons/ms-symbollockup_mssymbol_19.svg" alt="microsoft-login" />
                        <span *ngIf="hasMicrosoftConnected">Is verbonden met Microsoft</span>
                        <span *ngIf="!hasMicrosoftConnected">Niet verbonden met Microsoft</span>
                    </span>
                    <ng-container *ngIf="!isLoading; else loadingSpinner">
                        <a *ngIf="!hasMicrosoftConnected" class="connection-button" [href]="microsoftLoginUrl">Verbinden</a>
                        <button mat-button *ngIf="hasMicrosoftConnected" class="connection-button" (click)="unlink('Microsoft')">Loskoppelen</button>
                    </ng-container>
                </div>
                <div class="external-account-container">
                    <span class="gsi-material-button">
                        <div class="gsi-material-button-state"></div>
                        <div class="gsi-material-button-content-wrapper">
                            <div class="gsi-material-button-icon">
                                <img src="/assets/icons/gsi-logo.svg" alt="google-login">
                            </div>
                            <span class="gsi-material-button-contents">
                                <span *ngIf="hasGoogleConnected" class="me-12px">Is verbonden met Google</span>
                                <span *ngIf="!hasGoogleConnected" class="me-12px">Niet verbonden met Google</span>
                            </span>
                        </div>
                    </span>
                    <ng-container *ngIf="!isLoading; else loadingSpinner">
                        <a *ngIf="!hasGoogleConnected" class="connection-button" [href]="googleLoginUrl">Verbinden</a>
                        <button mat-button *ngIf="hasGoogleConnected" class="connection-button" (click)="unlink('Google')">Loskoppelen</button>
                    </ng-container>
                </div>
            </div>

            <div>
                <button mat-raised-button color="primary" class="full-width" (click)="close()">Close</button>
            </div>

        </mat-card-content>
    </mat-card>
</div>

<ng-template #loadingSpinner>
    <mat-spinner [diameter]="20"></mat-spinner>
</ng-template>
