import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TosComponent } from "./tos/tos.component";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { PdfViewerModule } from "ng2-pdf-viewer";



@NgModule({
    declarations: [ TosComponent ],
    imports: [
        CommonModule,
        MatCardModule,
        MatGridListModule,
        PdfViewerModule,
    ],
    exports: [ TosComponent ]
})
export class PublicModule { }
