import { Component, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/accounts/account.service";
import { AppConfig } from "src/app/services/config/app-config";

@Component({
    selector: "app-account",
    templateUrl: "./account.component.html",
    styleUrls: [ "./account.component.scss" ]
})
export class AccountComponent implements OnInit {
    protected isLoading: boolean = true;

    protected hasMicrosoftConnected: boolean = false;
    protected hasGoogleConnected: boolean = false;
    protected microsoftLoginUrl: string;
    protected googleLoginUrl: string;

    public constructor(
        private readonly accountService: AccountService,
        appConfig: AppConfig
    ) {
        // Please note that this does not work locally (because the IDF is not hosted on the same address as the Identity Api)
        this.microsoftLoginUrl = `${appConfig.Identity.BaseUrl}/api/v1/Accounts/login/Microsoft?redirectUrl=/account`;
        this.googleLoginUrl = `${appConfig.Identity.BaseUrl}/api/v1/Accounts/login/Google?redirectUrl=/account`;
    }

    public ngOnInit(): void {
        this.getConfiguredProviders().then(() => {
            // ingore as we don't need to do anything here
        }).catch((error: any) => {
            console.error(error);
        });
    }

    protected async removeProvider(provider: string): Promise<void> {
        this.isLoading = true;
        await this.accountService.removeProvider(provider);
        await this.accountService.getConfiguredProviders();
    }

    protected close() {
        window.close();
    }

    protected async unlink(provider: string): Promise<void> {
        await this.accountService.removeProvider(provider);
        await this.getConfiguredProviders();
    }

    private async getConfiguredProviders(): Promise<void> {
        try {
            const providers: string[] = await this.accountService.getConfiguredProviders();
            this.hasMicrosoftConnected = providers.includes("Microsoft");
            this.hasGoogleConnected = providers.includes("Google");

            this.isLoading = false;
        } catch (error) {
            console.error("Error while fetching configured providers", error);
        }
    }
}
