import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AppRoutingModule } from "src/app/app-routing.module";
import { SharedModule } from "../shared/shared.module";
import { LoginComponent } from "./components/login/login.component";
import { PasswordResetCompleteComponent } from "./components/password/password-reset-complete/password-reset-complete.component";
import { PasswordResetRequestCompleteComponent } from "./components/password/password-reset-request-complete/password-reset-request-complete.component";
import { PasswordResetRequestComponent } from "./components/password/password-reset-request/password-reset-request.component";
import { PasswordResetComponent } from "./components/password/password-reset/password-reset.component";
import { RegisterCompleteComponent } from "./components/register/register-complete/register-complete.component";
import { RegisterCompletedComponent } from "./components/register/register-completed/register-completed.component";
import { RegisterErrorComponent } from "./components/register/register-error/register-error.component";
import { RegisterWaitingForApprovalComponent } from "./components/register/register-waiting-for-approval/register-waiting-for-approval.component";
import { RegisterBasicComponent } from "./components/register/register-basic/register-basic.component";


@NgModule({
    declarations: [
        LoginComponent,
        RegisterBasicComponent,
        RegisterCompleteComponent,
        RegisterWaitingForApprovalComponent,
        RegisterErrorComponent,
        RegisterCompletedComponent,
        PasswordResetRequestComponent,
        PasswordResetCompleteComponent,
        PasswordResetRequestCompleteComponent,
        PasswordResetComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatStepperModule,
        AppRoutingModule,
        MatProgressSpinnerModule,
        SharedModule,
        MatIconModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatTooltipModule,
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true }
        }
    ]
})
export class AuthModule { }
