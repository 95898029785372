<div class="tos">
    <mat-card>
        <mat-card-content>
            <h1>Algemene voorwaarden {{tenantName}}</h1>
            <div [innerHTML]="tenantTermsOfService"></div>
        </mat-card-content>
    </mat-card>
</div>
<div class="tos">
    <mat-card>
        <mat-card-content>
            <h1>Algemene voorwaarden Spava</h1>
            <div>
                <pdf-viewer [src]="tosPdfUrl" [render-text]="true"></pdf-viewer>
            </div>
        </mat-card-content>
    </mat-card>
</div>
