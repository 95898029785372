import { Injectable, InjectionToken } from "@angular/core";
import { WebStyle, WebStyles } from "./identity.service";
import { MaterialCssVarsService } from "angular-material-css-vars";

@Injectable({
    providedIn: "root"
})
export class ThemingService {
    private readonly rootElement: HTMLElement;

    public constructor(
        private readonly materialCssVarsService: MaterialCssVarsService
    ) {
        this.rootElement = document.documentElement;
    }

    public updateStyling(webStyles: WebStyles[]): void {
        for (const webStyle of webStyles) {
            this.updateSingleStyle(webStyle);
        }
    }

    public updateSingleStyle(webStyle: WebStyles): void {
        const redHex = this.valueToHex(webStyle.red);
        const greenHex = this.valueToHex(webStyle.green);
        const blueHex = this.valueToHex(webStyle.blue);

        const styleHex = `#${redHex}${greenHex}${blueHex}`;

        switch (webStyle.style) {
            case WebStyle.Primary:
                this.materialCssVarsService.setPrimaryColor(styleHex);
                break;
            case WebStyle.Accent:
                this.materialCssVarsService.setAccentColor(styleHex);
                break;
            case WebStyle.Warning:
                this.materialCssVarsService.setWarnColor(styleHex);
                break;
            default:
                // custom css variables not used in material color theming
                break;
        }

        // legacy styling support
        const cssVariables = this.webStyleToCssVariable(webStyle.style);
        this.rootElement.style.setProperty(cssVariables[0], webStyle.red.toString());
        this.rootElement.style.setProperty(cssVariables[1], webStyle.green.toString());
        this.rootElement.style.setProperty(cssVariables[2], webStyle.blue.toString());
    }

    private valueToHex(value: number): string {
        const hex = value.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    }

    private webStyleToCssVariable(webStyle: WebStyle): string[] {
        switch (webStyle) {
            case WebStyle.Primary:
                return [
                    "--primary-color-R",
                    "--primary-color-G",
                    "--primary-color-B"
                ];
            case WebStyle.Accent:
                return [
                    "--accent-color-R",
                    "--accent-color-G",
                    "--accent-color-B"
                ];
            case WebStyle.Warning:
                return [
                    "--warning-color-R",
                    "--warning-color-G",
                    "--warning-color-B"
                ];
            case WebStyle.Success:
                return [
                    "--success-color-R",
                    "--success-color-G",
                    "--success-color-B"
                ];
            case WebStyle.Background:
                return [
                    "--background-color-R",
                    "--background-color-G",
                    "--background-color-B"
                ];
            default:
                throw new Error(`The presented WebStyle '${webStyle}' does not comply with the styles.`);
        }
    }
}

export const THEMING_SERVICE: InjectionToken<ThemingService> = new InjectionToken("THEMING_SERVICE");
