import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DialogData } from "src/app/modules/shared/generic-dialog/dialog-data";
import { GenericDialogComponent } from "src/app/modules/shared/generic-dialog/generic-dialog.component";
import { AuthService } from "src/app/services/auth/auth.service";
import { AccountsIdentityClient } from "src/app/services/identity.service";
import { StyleService } from "src/app/services/style/style.service";
import { ValidatorExpressions } from "src/app/services/validatorExpressions";
import { PasswordResetForm } from "./password-reset-form";

@Component({
    selector: "app-change-password",
    templateUrl: "./password-reset.component.html",
    styleUrls: [ "./password-reset.component.scss" ]
})
export class PasswordResetComponent implements OnInit {
    protected form: FormGroup<PasswordResetForm>;
    protected brandImage: string;
    protected readonly brandName: string;

    private userId: string | null;
    private code: string | null;

    public constructor(
        private readonly accountsIdentityClient: AccountsIdentityClient,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog,
        authService: AuthService,
        styleService: StyleService,
    ) {
        this.brandName = authService.getTenantName();
        this.brandImage = styleService.getLogoUrl();

        this.form = new FormGroup<PasswordResetForm>({
            newPassword1: new FormControl("", { validators: [ Validators.required, ValidatorExpressions.passwordLength ], nonNullable: true }),
            newPassword2: new FormControl("", { nonNullable: true }),
        },
        {
            validators: [ ValidatorExpressions.areEqual("newPassword1", "newPassword2") ]
        });
    }

    public ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.userId = params.userId;
            this.code = params.code;

            if (this.userId == null || this.code == null) {
                this.openMissingParamsDialog("Geen UserId of Code aanwezig", [
                    "Er is iets mis gegaan tijdens het laden van deze pagina.",
                    "Als u deze pagina hebt geopend via een email, neem aub contact op met het bedrijf",
                    `Indien u een wachtwoord wijziging wilt aanvragen kies voor 'Wijziging aanvragen'.`
                ]);
            }
        });
    }

    protected async submit(): Promise<void> {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const newPassword = this.form.controls.newPassword1.value;

            await this.accountsIdentityClient.resetPassword(this.userId ?? undefined, this.code ?? undefined, newPassword).toPromise();
            await this.router.navigate([ "passwordreset/complete" ]);
        }
    }

    protected openMissingParamsDialog(title: string, error: string[]): void {
        const dialogData: DialogData = {
            title,
            message: error,
            icon: "report",
            iconColor: "#ed4337",
            primaryButtonAction: () => {
                this.router.navigate([ "passwordreset/request" ])
                    .catch((_reason) => {
                        throw new Error("Could not navigate");
                    });
            },
            primaryButtonText: "Wijziging aanvragen",
            primaryButtonColor: "primary"
        };
        this.dialog.open(GenericDialogComponent, { data: dialogData, disableClose: true });
    }

    protected openErrorDialog(title: string, error: string[]): void {
        const dialogData: DialogData = {
            title,
            message: error,
            icon: "report",
            iconColor: "#ed4337",
        };
        this.dialog.open(GenericDialogComponent, { data: dialogData, disableClose: false });
    }
}
