import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GenericDialogComponent } from "./generic-dialog/generic-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TermsOfServiceDialogComponent } from "./terms-of-service-dialog/terms-of-service-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";


@NgModule({
    declarations: [ GenericDialogComponent, TermsOfServiceDialogComponent ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatGridListModule,
    ]
})
export class SharedModule { }
