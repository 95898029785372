<mat-grid-list cols=1 rowHeight="100%">
    <mat-grid-tile colspan=1>
        <div class="user_card">
            <div class="brand_logo_container">
                <img [src]="brandImage" alt="Logo" class="brand_logo" />
            </div>
            <h3>
                <b>Registratie bij {{brandName}} voltooid</b>
            </h3>
            <p>U kunt elk moment een bericht ontvangen om uw e-mailadres te bevestigen.</p>
        </div>
    </mat-grid-tile>
</mat-grid-list>
