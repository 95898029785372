import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, UrlSerializer } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { MaterialCssVarsModule } from "angular-material-css-vars";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AccountModule } from "./modules/account/account.module";
import { AuthModule } from "./modules/auth/auth.module";
import { PublicModule } from "./modules/public/public.module";
import { AppConfig } from "./services/config/app-config";
import { IDENTITY_API_BASE_URL } from "./services/identity.service";
import { LOCATION_API_BASE_URL } from "./services/location-api.service";
import { LOCATION_SERVICE, LocationService } from "./services/location/location.service";
import { LowerCaseUrlSerializer } from "./services/providers/LowerCaseUrlSerializer";
import { StyleService } from "./services/style/style.service";
import { THEMING_SERVICE, ThemingService } from "./services/theming.service";
import { ErrorHandlerModule } from "./shared/error-handlers/error-handler.module";

@NgModule(
    {
        declarations: [ AppComponent ],
        bootstrap: [ AppComponent ],
        imports: [
            BrowserModule,
            BrowserAnimationsModule,
            ErrorHandlerModule,
            AppRoutingModule,
            AuthModule,
            PublicModule,
            MatProgressSpinnerModule,
            MaterialCssVarsModule.forRoot({}),
            AccountModule
        ],
        providers: [
            {
                provide: APP_INITIALIZER,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                useFactory: () => () => {},
                deps: [ AppConfig, Sentry.TraceService ],
                multi: true,
            },
            {
                provide: Sentry.TraceService,
                deps: [ Router ],
            },
            { provide: IDENTITY_API_BASE_URL, deps: [ AppConfig ], useFactory: (appConfig: AppConfig) => appConfig.Identity.BaseUrl },
            { provide: LOCATION_API_BASE_URL, deps: [ AppConfig ], useFactory: (appConfig: AppConfig) => appConfig.LocationApi.BaseUrl },
            { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
            { provide: THEMING_SERVICE, useClass: ThemingService },
            { provide: LOCATION_SERVICE, useClass: LocationService },
            StyleService,
            provideHttpClient(withInterceptorsFromDi())
        ]
    }
)
export class AppModule {
}
