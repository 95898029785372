import { Component, OnDestroy, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { DialogData } from "src/app/modules/shared/generic-dialog/dialog-data";
import { GenericDialogComponent } from "src/app/modules/shared/generic-dialog/generic-dialog.component";
import { RoutingUtils } from "src/app/services/RoutingUtils";
import { AuthService } from "src/app/services/auth/auth.service";
import { AppConfig } from "src/app/services/config/app-config";
import { StyleService } from "src/app/services/style/style.service";
import { ValidatorExpressions } from "src/app/services/validatorExpressions";
import { AccountsIdentityClient, UserCreateResource } from "../../../../../services/identity.service";
import { LoginInformationForm } from "./login-information-form";
import { RegisterBasicForm } from "./register-basic-form";
import { UserInformationForm } from "./user-information-form";

@Component({
    selector: "app-register-basic",
    templateUrl: "./register-basic.component.html",
    styleUrls: [ "./register-basic.component.scss" ]
})
export class RegisterBasicComponent implements OnDestroy {
    protected readonly form: FormGroup<RegisterBasicForm>;
    protected readonly brandName: string;
    protected readonly showBackToLogin: boolean;

    protected readonly userInformationForm: FormGroup<UserInformationForm>;
    protected readonly loginInformationForm: FormGroup<LoginInformationForm>;

    protected readonly brandImage: string;

    @ViewChild("registrationStepper")
    protected readonly registrationStepper: MatStepper;
    protected tosUrl: string;
    protected privacyUrl: string;

    private readonly componentDestroyed: Subject<void> = new Subject<void>();

    public constructor(
        private readonly auth: AuthService,
        private readonly accountIdentityServerClient: AccountsIdentityClient,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        route: ActivatedRoute,
        styleService: StyleService,
        config: AppConfig,
    ) {
        this.tosUrl = config.TermsOfServicePdfUrl;
        this.privacyUrl = config.PrivacyUrl;

        this.brandName = this.auth.getTenantName();

        this.userInformationForm = new FormGroup<UserInformationForm>({
            // User information
            firstName: new FormControl("", { validators: Validators.required, nonNullable: true }),
            lastName: new FormControl("", { validators: Validators.required, nonNullable: true }),
            phoneNumber: new FormControl("", { validators: Validators.pattern(ValidatorExpressions.phoneExpression), nonNullable: true }),
        });
        this.loginInformationForm = new FormGroup<LoginInformationForm>({
            // Login information
            email: new FormControl("", { validators: [ Validators.pattern(ValidatorExpressions.emailExpression), Validators.required ], nonNullable: true }),
            password: new FormControl("", { validators: [ ValidatorExpressions.passwordLength, Validators.required ], nonNullable: true }),
            passwordRepeat: new FormControl("", { nonNullable: true }),
            emailUsage: new FormControl(false, { validators: [ Validators.requiredTrue ], nonNullable: true }),
            eulaAcceptance: new FormControl(false, { validators: [ Validators.requiredTrue ], nonNullable: true }),
            isHelpingImprovingTheProduct: new FormControl(false, { nonNullable: true }),
        }, {
            validators: [ ValidatorExpressions.areEqual("password", "passwordRepeat") ]
        });

        this.form = new FormGroup<RegisterBasicForm>({
            userInformationForm: this.userInformationForm,
            loginInformationForm: this.loginInformationForm
        });

        this.brandImage = styleService.getLogoUrl();

        route.queryParams.pipe(takeUntil(this.componentDestroyed)).subscribe((queryParams: Params) => {
            const firstName = RoutingUtils.getQueryParameterInsensitive(queryParams, "firstName");
            if (firstName != null) {
                this.userInformationForm.controls.firstName.setValue(firstName);
                this.userInformationForm.controls.firstName.markAsTouched();
            }

            const lastName = RoutingUtils.getQueryParameterInsensitive(queryParams, "lastName");
            if (lastName != null) {
                this.userInformationForm.controls.lastName.setValue(lastName);
                this.userInformationForm.controls.lastName.markAsTouched();
            }

            const phoneNumber = RoutingUtils.getQueryParameterInsensitive(queryParams, "phoneNumber");
            if (phoneNumber != null) {
                this.userInformationForm.controls.phoneNumber.setValue(phoneNumber);
                this.userInformationForm.controls.phoneNumber.markAsTouched();
            }

            const email = RoutingUtils.getQueryParameterInsensitive(queryParams, "email");
            if (email != null) {
                this.loginInformationForm.controls.email.setValue(email);
                this.loginInformationForm.controls.email.markAsTouched();
            }
        });

        this.showBackToLogin = window.sessionStorage.getItem("redirectUrl") != null;
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    protected async register(registerButton: MatButton): Promise<void> {
        registerButton.disabled = true;
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const userCreateResource: UserCreateResource = {
                firstName: this.userInformationForm.controls.firstName.value,
                lastName: this.userInformationForm.controls.lastName.value,
                username: this.loginInformationForm.controls.email.value,
                password: this.loginInformationForm.controls.password.value,
                phoneNumber: this.userInformationForm.controls.phoneNumber.value === "" ?
                    null :
                    this.userInformationForm.controls.phoneNumber.value,
                isHelpingImprovingTheProduct: this.loginInformationForm.controls.isHelpingImprovingTheProduct.value,
                tenantName: this.auth.getTenantName(),
            };

            try {
                await this.accountIdentityServerClient.register(userCreateResource).toPromise();
                await this.router.navigate([ "register/complete" ]);
            } catch (exception) {
                this.handleError(exception);
            }
        }

        registerButton.disabled = false;
    }

    protected openErrorDialog(title: string, error: string[]): void {
        const dialogData: DialogData = {
            title,
            message: error,
            icon: "report",
            iconColor: "#ed4337",
        };
        this.dialog.open(GenericDialogComponent, { data: dialogData, disableClose: false });
    }

    protected validateUserInformationAndNextStep(): void {
        this.userInformationForm.markAllAsTouched();

        if (this.userInformationForm.valid) {
            this.registrationStepper.next();
        }
    }

    private handleError(exception: any): void {
        let errorStrings = [];

        if (Array.isArray(exception)) {
            errorStrings = exception.map((ex: any) => ex.description as never);
        } else if (typeof exception == "string") {
            errorStrings = [ exception as never ];
        } else if (exception.errors != null) {
            for (const key of Object.keys(exception.errors)) {
                errorStrings.push(`${key}: ${exception.errors[key]}` as never);
            }
        } else {
            errorStrings = [ exception as never ];
        }

        this.openErrorDialog("Registreerfout", errorStrings);
    }
}
