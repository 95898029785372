import { DefaultUrlSerializer, UrlTree } from "@angular/router";

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    private readonly queryParamIdentifier: string = "?";

    public parse(url: string): UrlTree {
        if (url.includes(this.queryParamIdentifier)) {
            return this.ExcludeQueryParamsToLowerCase(url);
        }
        return super.parse(url.toLowerCase());
    }

    private ExcludeQueryParamsToLowerCase(url: string): UrlTree {
        const split = url.split(this.queryParamIdentifier);
        split[0] = split[0].toLowerCase();
        return super.parse(split.join(this.queryParamIdentifier));
    }
}
