<mat-grid-list cols="1" rowHeight="100%">
    <mat-grid-tile colspan="1">
        <div class="user_card">
            <div class="brand_logo_container">
                <img [src]="brandImage" alt="Logo" class="brand_logo" />
            </div>
            <h3>
                <b>Wachtwoord wijzigen bij {{ brandName }}</b>
            </h3>
            <p>Geef hier een nieuw wachtwoord op.</p>
            <form [formGroup]="form" (ngSubmit)="submit()">
                <mat-grid-list cols="1" rowHeight="75px">
                    <mat-grid-tile colspan="1">
                        <mat-form-field>
                            <mat-label>Nieuw wachtwoord</mat-label>
                            <input matInput id="newPassword1" class="form-control password-input" type="password" [formControl]="this.form.controls.newPassword1" placeholder="Huidig wachtwoord" required>
                            <mat-icon class="helper-tooltip" matTooltip="Een wachtwoord moet minimaal 12 tekens lang zijn" aria-label="Some icon">info</mat-icon>
                            <mat-error *ngIf="this.form.controls.newPassword1.touched && this.form.controls.newPassword1.invalid" color="warn">
                                U bent verplicht een nieuw wachtwoord op te geven.
                            </mat-error>
                        </mat-form-field>
                    </mat-grid-tile>
                    <ng-container>
                        <mat-grid-tile colspan="1">
                            <mat-form-field>
                                <mat-label>Herhaal wachtwoord</mat-label>
                                <input matInput class="form-control password-input" type="password" [formControl]="this.form.controls.newPassword2" placeholder="Huidig wachtwoord" required>
                                <mat-icon class="helper-tooltip" matTooltip="Een wachtwoord moet minimaal 12 tekens lang zijn" aria-label="Some icon">info</mat-icon>
                                <mat-error *ngIf="this.form.controls.newPassword2.touched && this.form.controls.newPassword2.invalid" color="warn">
                                    De wachtwoorden moeten overeenkomen.
                                </mat-error>
                            </mat-form-field>
                        </mat-grid-tile>
                    </ng-container>
                </mat-grid-list>
                <button mat-raised-button color="primary">Wijzigen</button>
            </form>
        </div>
    </mat-grid-tile>
</mat-grid-list>
