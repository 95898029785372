<mat-grid-list cols="1" rowHeight="100%">
    <mat-grid-tile colspan="1">
        <div class="user_card">
            <div class="brand_logo_container">
                <img [src]="logoUrl" alt="Logo" class="brand_logo" />
            </div>
            <h3><b>Registratie bij {{brandName}} afgerond</b></h3>
            <p>Het account is geactiveerd.</p>
        </div>
    </mat-grid-tile>
</mat-grid-list>