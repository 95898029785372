import { enableProdMode, setTestabilityGetter } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppConfig } from './app/services/config/app-config';
import * as Sentry from "@sentry/angular";

fetch('/assets/config/appsettings.json', {
    cache: 'no-cache'
})
    .then(response => response.json())
    .then((appConfig: AppConfig) => {
        if (appConfig.Production) {
            enableProdMode();
        }
        initializeSentry(appConfig.SentryDsn);

        platformBrowserDynamic([
            { provide: AppConfig, useValue: appConfig }
        ]).bootstrapModule(AppModule).then(ref => {
            // Ensure Angular destroys itself on hot reloads.
            if (window['ngRef']) {
                window['ngRef'].destroy();
            }
            window['ngRef'] = ref;

            // Otherwise, log the boot error
        }).catch(err => console.error(err));
    })
    .catch((error: any) => {
        console.error(`Could not retrieve appConfig: ${error}`);
    });

    function initializeSentry(dsn: string) {
        Sentry.init({
            dsn: dsn,
            integrations: [
                Sentry.browserTracingIntegration(),
            ],
            tracePropagationTargets: [ "localhost" ],
            tracesSampleRate: 1.0
        })
    }
