import { Params, Routes } from "@angular/router";

export class RoutingUtils {
    public static prepare(routes: Routes): Routes {
        for (let i = 0; i < routes.length; i++) {
            routes[i].path = this.fixPath(routes[i].path ?? "");
        }

        return routes;
    }

    private static fixPath(path: string): string {
        const routeSegments = path.split("/");

        for (let i = 0; i < routeSegments.length; i++) {
            const segment = routeSegments[i];

            if (!segment.startsWith(":")) {
                routeSegments[i] = segment.toLowerCase();
            }
        }

        return routeSegments.join("/");
    }

    public static getQueryParameterInsensitive(queryParams: Params, parameter: string): string | null {
        // Get all the keys from queryParams (these are case sensitive)
        const keys: string[] = Object.keys(queryParams);

        // Loop through the keys and check if any of them match the parameter (case insensitive)
        for (const key of keys) {
            if (key.toLowerCase() === parameter.toLowerCase()) {
                // Return the value of the matching key
                return queryParams[key] as string;
            }
        }

        return null;
    }
}
