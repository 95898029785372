import { Component } from "@angular/core";
import { AuthService } from "src/app/services/auth/auth.service";
import { StyleService } from "src/app/services/style/style.service";

@Component({
    selector: "app-password-reset-request-complete",
    templateUrl: "./password-reset-request-complete.component.html",
    styleUrls: [ "./password-reset-request-complete.component.scss" ]
})
export class PasswordResetRequestCompleteComponent {
    protected readonly brandImage: string;
    protected readonly brandName: string;

    public constructor(styleService: StyleService, authService: AuthService) {
        this.brandImage = styleService.getLogoUrl();
        this.brandName = authService.getTenantName();
    }
}
