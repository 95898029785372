import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params } from "@angular/router";
import { first } from "rxjs";
import { DialogData } from "src/app/modules/shared/generic-dialog/dialog-data";
import { GenericDialogComponent } from "src/app/modules/shared/generic-dialog/generic-dialog.component";

@Component({
    selector: "app-register-error",
    templateUrl: "./register-error.component.html",
    styleUrls: [ "./register-error.component.scss" ]
})
export class RegisterErrorComponent implements OnInit {
    public constructor(private readonly activatedRoute: ActivatedRoute, private readonly dialog: MatDialog) {
    }

    public ngOnInit(): void {
        this.activatedRoute.queryParams.pipe(first()).subscribe((params: Params) => {
            const messagesString = params.messages;
            const messagesArray = messagesString.split(";");

            this.openErrorDialog("Registratie error", messagesArray);
        });
    }

    protected openErrorDialog(title: string, error: string[]): void {
        const dialogData: DialogData = {
            title,
            message: error,
            icon: "report",
            iconColor: "#ed4337",
        };
        this.dialog.open(GenericDialogComponent, { data: dialogData, disableClose: true, minWidth: "300px" });
    }
}
