import { Injectable } from "@angular/core";
import { AccountsIdentityClient } from "../identity.service";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AccountService {

    public constructor(
        private readonly identityClient: AccountsIdentityClient
    ) { }

    public async getConfiguredProviders(): Promise<string[]> {
        const providers = await lastValueFrom(this.identityClient.providers());
        return providers;
    }

    public async removeProvider(provider: string): Promise<void> {
        await lastValueFrom(this.identityClient.loginDELETE(provider));
    }
}
