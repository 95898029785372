import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from "@angular/forms";

export class ValidatorExpressions {
    public static readonly emailExpression: RegExp = /.+@\w+\.\w+/;
    public static readonly phoneExpression: RegExp = /^(((0)[1-9]{2}[0-9][\s|-]?[1-9][0-9]{5})|((\+31|0|0031)[1-9][0-9][[\s|-]?[1-9][0-9]{6})|(((\+31|0|0031)6){1}[\s|-]?[1-9]{1}[0-9]{7})|)$/;
    public static readonly postalCodeExpression: RegExp = /^^[1-9][0-9]{3}(?!SA|SD|SS)[A-Z]{2}$/;
    public static readonly houseNumberExpression: RegExp = /^[1-9]\d*[a-zA-Z]*\d*$/;
    public static readonly vatExpression: RegExp = /^(NL)?\d{9}B\d{2}$/;

    public static passwordLength: ValidatorFn = Validators.minLength(12);

    public static areEqual(field1: string, field2: string): ValidatorFn {
        return (group: AbstractControl): ValidationErrors | null => {
            const value1 = group.get(field1)?.value;
            const control2 = group.get(field2);
            const value2 = control2?.value;

            if (value1 === value2) {
                control2?.setErrors(null);
            } else {
                control2?.setErrors({ notSame: true });
            }

            return null;
        };
    }
}
