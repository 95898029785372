import { Injectable } from "@angular/core";
import { IdentityConfig } from "./identity-config";
import { LocationApiConfig } from "./location-api-config";

@Injectable()
export class AppConfig {
    public Production: boolean;
    public Identity: IdentityConfig;
    public LocationApi: LocationApiConfig;
    public SentryDsn: string;
    public PrivacyUrl: string;
    public TermsOfServicePdfUrl: string;
    public DisableRegistrationNavigation: boolean;
    public DisableSocialLogin: boolean;
}
